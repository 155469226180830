import {
	Card,
	Grid,
	Text,
	Metric,
	DeltaBar,
	Flex,
	Title,
	BarChart,
	LineChart,
	TabGroup,
	TabList,
	Tab,
} from '@tremor/react';

import React, { useState } from 'react';

import { startOfMonth } from 'date-fns';
import API from './api';
import APIDataProvider from './api/APIDataProvider';

import {
	formatBigMoney,
	formatBigMoneyChange,
	percentChange,
} from './utils/format';

export default function () {
	const from = startOfMonth(new Date());
	const to = new Date();
	const [includeExcluded, setIncludeExcluded] = useState(0);
	const [platformIndex, setPlatformIndex] = useState(0);

	const platform = ['all', 'onlyfans', 'playboy', 'onlyfansfree', 'fansly'][
		platformIndex
	];

	const targets = {
		onlyfans: 1600,
		playboy: 300,
		onlyfansfree: 30,
		fansly: 30,
		all: 1960,
	};
	const target = targets[platform];

	const now = new Date();
	const endOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
	const startOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
	const totalTime = endOfThisMonth - startOfThisMonth;
	const targetForMonth = (totalTime / (1000 * 60 * 60 * 24)) * target;
	const timePassed = now - startOfThisMonth;
	const timePassedAsDays = timePassed / (1000 * 60 * 60 * 24);
	const targetPosition = timePassedAsDays * target;
	return (
		<>
			<Flex justifyContent="end">
				<TabGroup
					onIndexChange={setIncludeExcluded}
					index={includeExcluded}
					className="w-auto"
				>
					<TabList variant="solid" className="mt-6 mr-6">
						<Tab>Exclude</Tab>
						<Tab>Include Excluded</Tab>
					</TabList>
				</TabGroup>
				<TabGroup
					onIndexChange={setPlatformIndex}
					index={platformIndex}
					className="w-auto"
				>
					<TabList variant="solid" className="mt-6">
						<Tab>All</Tab>
						<Tab>Onlyfans</Tab>
						<Tab>Playboy</Tab>
						<Tab>Free</Tab>
						<Tab>Fansly</Tab>
					</TabList>
				</TabGroup>
			</Flex>
			<Grid numItemsMd={3} numItemsLg={4} className="gap-6 mt-6">
				<APIDataProvider
					method={API.stats.bind(API)}
					options={{
						...(includeExcluded
							? { includeExcluded: true, platform }
							: { platform }),
					}}
				>
					{(stats, loading) => (
						<>
							<Card>
								<Text>Month so far</Text>
								<Metric>
									{stats ? formatBigMoney(stats.month) : 'Loading'}
								</Metric>
								<Flex className="mt-6">
									<Text>vs.&nbsp;Last&nbsp;Month</Text>
									<Flex
										alignItems="baseline"
										justifyContent="end"
										className="space-x-1"
									>
										<Text>
											{stats
												? formatBigMoneyChange(
														stats.month - stats.lastMonthToNow
												  )
												: 'Loading'}
										</Text>
										<Text>
											(
											{stats
												? percentChange(stats.month / stats.lastMonthToNow - 1)
												: 'Loading'}
											)
										</Text>
									</Flex>
								</Flex>
								<DeltaBar
									value={
										stats ? (stats.month / stats.lastMonthToNow - 1) * 100 : 0
									}
									isIncreasePositive
									className="mt-3"
								/>
								<Flex className="mt-6">
									<Text>vs.&nbsp;Target</Text>
									<Flex
										alignItems="baseline"
										justifyContent="end"
										className="space-x-1"
									>
										<Text>
											{stats
												? formatBigMoneyChange(stats.month - targetPosition)
												: 'Loading'}
										</Text>
										<Text>
											(
											{stats
												? percentChange(stats.month / targetPosition - 1)
												: 'Loading'}
											)
										</Text>
									</Flex>
								</Flex>
								<DeltaBar
									value={stats ? (stats.month / targetPosition - 1) * 100 : 0}
									isIncreasePositive
									className="mt-3"
								/>
							</Card>
							<Card>
								<Text>Predicted</Text>
								<Metric>
									{stats ? formatBigMoney(stats.predicted) : 'Loading'}
								</Metric>
								<Flex className="mt-6">
									<Text>vs.&nbsp;Last&nbsp;Month</Text>
									<Flex
										alignItems="baseline"
										justifyContent="end"
										className="space-x-1"
									>
										<Text>
											{stats
												? formatBigMoneyChange(
														stats.predicted - stats.lastMonth
												  )
												: 'Loading'}
										</Text>
										<Text>
											(
											{stats
												? percentChange(stats.predicted / stats.lastMonth - 1)
												: 'Loading'}
											)
										</Text>
									</Flex>
								</Flex>
								<DeltaBar
									value={
										stats ? (stats.predicted / stats.lastMonth - 1) * 100 : 0
									}
									isIncreasePositive
									className="mt-3"
								/>
							</Card>
							<Flex className="flex-col">
								<Card className="mb-6">
									<Text>Last month to current date</Text>
									<Metric>
										{stats ? formatBigMoney(stats.lastMonthToNow) : 'Loading'}
									</Metric>
								</Card>
								<Card>
									<Text>Last month</Text>
									<Metric>
										{stats ? formatBigMoney(stats.lastMonth) : 'Loading'}
									</Metric>
								</Card>
							</Flex>
							<Flex className="flex-col">
								<Card className="mb-6">
									<Text>Target to date</Text>
									<Metric>
										{stats ? formatBigMoney(targetPosition) : 'Loading'}
									</Metric>
								</Card>
								<Card>
									<Text>Target for month</Text>
									<Metric>
										{stats ? formatBigMoney(targetForMonth) : 'Loading'}
									</Metric>
								</Card>
							</Flex>
						</>
					)}
				</APIDataProvider>
			</Grid>
			<Grid numItemsMd={1} numItemsLg={2} className="gap-6 mt-6">
				<APIDataProvider
					method={API.sales.bind(API)}
					options={{
						from,
						to,
						timeGroup: 'day',
						platform,
						...(includeExcluded ? { includeExcluded: true } : {}),
					}}
				>
					{(sales, loading) => (
						<>
							<Card>
								<Title>Daily sales</Title>
								<BarChart
									className="mt-6 h-56 sm:h-72"
									data={sales || []}
									index="date"
									categories={['net']}
									colors={['emerald']}
									valueFormatter={formatBigMoney}
									yAxisWidth={40}
								/>
							</Card>
							<Card>
								<Title>Cumulative sales</Title>
								<LineChart
									className="mt-6 h-56 sm:h-72"
									data={(sales || []).reduce((o, x, i, a) => {
										o.push({
											...x,
											Cumulative: (o[i - 1] ? o[i - 1].Cumulative : 0) + x.net,
											Target: (i + 1) * target,
										});
										return o;
									}, [])}
									index="date"
									categories={['Cumulative', 'Target']}
									colors={['cyan', 'gray']}
									valueFormatter={formatBigMoney}
									yAxisWidth={40}
								/>
							</Card>
						</>
					)}
				</APIDataProvider>
			</Grid>
		</>
	);
}
