import React, { useState } from 'react';
import {
	Card,
	Grid,
	Text,
	Metric,
	DeltaBar,
	Flex,
	Title,
	LineChart,
	BarList,
	DonutChart,
	Legend,
	Icon,
	Bold,
	MarkerBar,
	TabGroup,
	TabList,
	Tab,
} from '@tremor/react';
import {
	startOfDay,
	addDays,
	differenceInMinutes,
	subDays,
	endOfDay,
} from 'date-fns';
import API from './api';
import APIDataProvider from './api/APIDataProvider';

import {
	formatBigMoney,
	formatBigMoneyChange,
	niceNumber,
	percentChange,
	formatChange,
} from './utils/format';

const processTodayVsAverage = (todayVsAverage, returnFull, platform) => {
	if (!todayVsAverage) return {};
	const minutesToday = Math.floor(
		differenceInMinutes(new Date(), startOfDay(new Date())) / 15
	);
	const target = {
		onlyfans: 1800,
		onlyfansfree: 30,
		playboy: 300,
		fansly: 30,
		all: 2160,
	}[platform];

	const withRolling = todayVsAverage.map((x, i, a) => ({
		...x,
		rollingAverage: a
			.slice(Math.max(0, i - 4), i)
			.reduce((o, x, i, a) => o + x.average / a.length, 0),
	}));
	const multiplier =
		target / withRolling[withRolling.length - 1].rollingAverage;

	const todayVsAvg = withRolling
		.map((x, i, a) => ({
			...x,
			target: x.rollingAverage * multiplier,
			targetVsActualPct: x.today / (x.rollingAverage * multiplier) - 1,
			targetVsActualAmt: x.today - x.rollingAverage * multiplier,
		}))
		.slice(0, minutesToday + 1);

	const todayVsAvgComparison = todayVsAvg[todayVsAvg.length - 1];
	if (returnFull) return todayVsAvg;
	return todayVsAvgComparison;
};

export default function () {
	const from = startOfDay(new Date());
	const to = addDays(from, 1);
	const fromWeekAgo = subDays(from, 7);
	const endOfToday = endOfDay(new Date());
	const weekAgo = subDays(new Date(), 7);
	const pendingTargets = {
		Message: 10000,
		Post: 3000,
		Tip: 5000,
	};

	const [includeExcluded, setIncludeExcluded] = useState(0);
	const [absoluteToday, setAbsoluteToday] = useState(0);
	const [platformIndex, setPlatformIndex] = useState(0);

	const platform = ['all', 'onlyfans', 'playboy', 'onlyfansfree', 'fansly'][
		platformIndex
	];

	return (
		<>
			<Flex justifyContent="end">
				<TabGroup
					onIndexChange={setIncludeExcluded}
					index={includeExcluded}
					className="w-auto mr-6"
				>
					<TabList variant="solid" className="mt-6">
						<Tab>Exclude</Tab>
						<Tab>Include Excluded</Tab>
					</TabList>
				</TabGroup>
				<TabGroup
					onIndexChange={setPlatformIndex}
					index={platformIndex}
					className="w-auto"
				>
					<TabList variant="solid" className="mt-6">
						<Tab>All</Tab>
						<Tab>Onlyfans</Tab>
						<Tab>Playboy</Tab>
						<Tab>Free</Tab>
						<Tab>Fansly</Tab>
					</TabList>
				</TabGroup>
			</Flex>
			<Grid numItemsMd={3} numItemsLg={5} className="gap-6 mt-6">
				<APIDataProvider
					method={API.todayVsAverage.bind(API)}
					options={{
						...(includeExcluded
							? { includeExcluded: true, platform }
							: { platform }),
					}}
				>
					{(todayVsAverage, loading2) => (
						<Card>
							<Text>Sales</Text>
							<Metric>
								{todayVsAverage
									? formatBigMoney(
											processTodayVsAverage(todayVsAverage, false, platform)
												.today
									  )
									: 'Loading'}
							</Metric>
							<Flex className="mt-6">
								<Text>vs.&nbsp;Target</Text>
								<Flex
									alignItems="baseline"
									justifyContent="end"
									className="space-x-1"
								>
									<Text>
										{formatBigMoneyChange(
											processTodayVsAverage(todayVsAverage, false, platform)
												.targetVsActualAmt
										)}
									</Text>
									<Text>
										(
										{percentChange(
											processTodayVsAverage(todayVsAverage, false, platform)
												.targetVsActualPct
										)}
										)
									</Text>
								</Flex>
							</Flex>
							<DeltaBar
								value={
									todayVsAverage
										? processTodayVsAverage(todayVsAverage, false, platform)
												.targetVsActualPct * 100
										: 0
								}
								isIncreasePositive
								className="mt-3"
							/>
						</Card>
					)}
				</APIDataProvider>
				<APIDataProvider method={API.metrics.bind(API)}>
					{(metrics) => (
						<APIDataProvider
							method={API.metrics.bind(API)}
							options={subDays(new Date(), 1).toUTCString()}
						>
							{(comparison) => (
								<>
									<Card>
										<Text>Pending</Text>
										{!comparison || !metrics ? (
											<Text>Loading</Text>
										) : (
											<>
												<Metric>{formatBigMoney(metrics.payoutPending)}</Metric>
												<Flex className="mt-6">
													<Text>vs.&nbsp;Yesterday</Text>
													<Flex
														alignItems="baseline"
														justifyContent="end"
														className="space-x-1"
													>
														<Text>
															{formatBigMoneyChange(
																metrics.payoutPending - comparison.payoutPending
															)}
														</Text>
														<Text>
															(
															{percentChange(
																metrics.payoutPending /
																	comparison.payoutPending -
																	1
															)}
															)
														</Text>
													</Flex>
												</Flex>
												<DeltaBar
													value={
														(metrics.payoutPending / comparison.payoutPending -
															1) *
														100
													}
													isIncreasePositive
													className="mt-3"
												/>
											</>
										)}
									</Card>
									<Card>
										<Text>Fans</Text>
										{!comparison || !metrics ? (
											<Text>Loading</Text>
										) : (
											<>
												<Metric>{niceNumber(metrics.fans, 0)}</Metric>
												<Flex className="mt-6">
													<Text>vs.&nbsp;Yesterday </Text>
													<Flex
														alignItems="baseline"
														justifyContent="end"
														className="space-x-1"
													>
														<Text>
															{formatChange(metrics.fans - comparison.fans)}
														</Text>
														<Text>
															(
															{percentChange(
																metrics.fans / comparison.fans - 1
															)}
															)
														</Text>
													</Flex>
												</Flex>
												<DeltaBar
													value={(metrics.fans / comparison.fans - 1) * 100}
													isIncreasePositive
													className="mt-3"
												/>
											</>
										)}
									</Card>

									<Card>
										<Text>Following</Text>
										{!comparison || !metrics ? (
											<Text>Loading</Text>
										) : (
											<>
												<Metric>{niceNumber(metrics.following, 0)}</Metric>
												<Flex className="mt-6">
													<Text>vs.&nbsp;Yesterday</Text>
													<Flex
														alignItems="baseline"
														justifyContent="end"
														className="space-x-1"
													>
														<Text>
															{formatChange(
																metrics.following - comparison.following
															)}
														</Text>
														<Text>
															(
															{percentChange(
																metrics.following / comparison.following - 1
															)}
															)
														</Text>
													</Flex>
												</Flex>
												<DeltaBar
													value={
														(metrics.following / comparison.following - 1) * 100
													}
													isIncreasePositive
													className="mt-3"
												/>
											</>
										)}
									</Card>

									<Card>
										<Text>Unique Paying Users</Text>
										<Metric>
											{metrics
												? niceNumber(metrics.uniquePayingUsers)
												: 'Loading'}
										</Metric>
									</Card>
								</>
							)}
						</APIDataProvider>
					)}
				</APIDataProvider>
			</Grid>
			<div className="mt-6">
				<Grid numItemsMd={1} numItemsLg={2} className="gap-6 mt-6">
					<APIDataProvider
						method={API.todayVsAverage.bind(API)}
						options={{
							...(includeExcluded
								? { includeExcluded: true, platform }
								: { platform }),
						}}
					>
						{(todayVsAverage) => (
							<Card>
								<Flex justifyContent="between">
									<Title className="w-full">Today&apos;s Sales</Title>
									<TabGroup
										className="w-fit"
										onIndexChange={setAbsoluteToday}
										index={absoluteToday}
									>
										<TabList variant="solid">
											<Tab>Absolute</Tab>
											<Tab>Behind</Tab>
										</TabList>
									</TabGroup>
								</Flex>
								<LineChart
									className="mt-6 h-44 sm:h-72"
									data={
										todayVsAverage
											? processTodayVsAverage(
													todayVsAverage,
													true,
													platform
											  ).map((x) => ({
													Net: x.today,
													Target: x.target,
													Average: x.rollingAverage,
													Behind: x.today - x.target,
											  }))
											: []
									}
									index="minute"
									categories={
										absoluteToday ? ['Behind'] : ['Net', 'Target', 'Average']
									}
									colors={['purple', 'emerald', 'gray']}
									valueFormatter={formatBigMoney}
									yAxisWidth={40}
								/>
							</Card>
						)}
					</APIDataProvider>

					<APIDataProvider
						method={API.sales.bind(API)}
						options={{
							from: weekAgo,
							to: new Date(),
							groupType: true,
							platform,
							...(includeExcluded ? { includeExcluded: true } : {}),
						}}
					>
						{(sales) => (
							<Card>
								<Title>Last week performance</Title>
								{(sales || []).map((item) => (
									<Flex
										key={item.type}
										justifyContent="start"
										className="space-x-6 mt-6"
									>
										{/* <Icon
											icon={item.icon}
											color="blue"
											variant="shadow"
											size="lg"
										/> */}
										<div className="space-y-2 w-full">
											<Flex>
												<Text>
													<Bold>{item.type}</Bold>
												</Text>
												<Text>
													{percentChange(
														(item.net - pendingTargets[item.type]) /
															pendingTargets[item.type]
													)}{' '}
													({formatBigMoney(item.net)})
												</Text>
											</Flex>
											<DeltaBar
												value={
													((item.net - pendingTargets[item.type]) /
														pendingTargets[item.type]) *
													100
												}
											/>
											<Flex justifyContent="center">
												<Text>{formatBigMoney(pendingTargets[item.type])}</Text>
											</Flex>
										</div>
									</Flex>
								))}
							</Card>
						)}
					</APIDataProvider>

					<APIDataProvider
						method={API.sales.bind(API)}
						options={{
							from: fromWeekAgo,
							to: endOfToday,
							timeGroup: 'day',
							platform,
							...(includeExcluded ? { includeExcluded: true } : {}),
						}}
					>
						{(sales) => (
							<Card>
								<Flex justifyContent="between">
									<Title className="whitespace-nowrap">Last 7 Day Sales</Title>
									{/* <TabGroup>
										<TabList>
											<Tab>Total</Tab>
											<Tab>Message</Tab>
											<Tab>Tip</Tab>
											<Tab>Post</Tab>
										</TabList>
									</TabGroup> */}
								</Flex>
								<BarList
									data={(sales || []).map((x) => ({
										name: x.date,
										value: x.net,
									}))}
									sortOrder="none"
									className="mt-6"
									valueFormatter={formatBigMoney}
								/>
							</Card>
						)}
					</APIDataProvider>

					<APIDataProvider
						method={API.sales.bind(API)}
						options={{
							from: fromWeekAgo,
							to: endOfToday,
							timeGroup: 'day',
							compareToTime: true,
							platform,
							...(includeExcluded ? { includeExcluded: true } : {}),
						}}
					>
						{(sales, loading) => (
							<Card>
								<Title>Last 7 Day Sales to current time</Title>
								<BarList
									data={(sales || []).map((x) => ({
										name: x.date,
										value: x.net,
									}))}
									className="mt-6"
									sortOrder="none"
									valueFormatter={formatBigMoney}
								/>
							</Card>
						)}
					</APIDataProvider>
				</Grid>
			</div>
		</>
	);
}
